import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import WhiteButton from "./WhiteButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"


const IntegrateZycada = () => (
  <div className={"flex justify-center"}>
    <div className="flex flex-wrap justify-center mb-8 md:w-4/5">
      <div className={"w-full text-center"}>
        <h2 className="">Integrate Zycada in minutes</h2>

        <svg className="hidden sm:flex sm:w-4/5 mx-auto" xmlns="http://www.w3.org/2000/svg" width="871"
             viewBox="0 0 871 131">
          <g fill="none" fillRule="evenodd" transform="translate(3 3)">
            <path stroke="#FF6329" strokeWidth="5"
                  d="M532,0 L584,62.5 L532,125 L280,125 L332,62.5 L280,0 L532,0 Z"/>
            <path stroke="#FF6329" strokeWidth="5"
                  d="M812,0 L864,62.5 L812,125 L560,125 L560,123.798 L611,62.5 L560,1.202 L560,0 L812,0 Z"/>
            <polygon stroke="#FF6329" strokeWidth="5" points="0 0 253 0 305 62.5 253 125 0 125"/>
            <g opacity=".1" transform="translate(99.301 15)">
              <rect width="100" height="100" x="1.397"/>
              <path fill="#000" fillRule="nonzero"
                    d="M95.3970149,50 L85.1933786,38.3085714 L86.6151967,22.8457143 L71.5188331,19.4095238 L63.6151967,6 L49.3970149,12.1180952 L35.1788331,6 L27.2751967,19.367619 L12.1788331,22.7619048 L13.6006513,38.2666667 L3.39701493,50 L13.6006513,61.6914286 L12.1788331,77.1961905 L27.2751967,80.632381 L35.1788331,94 L49.3970149,87.84 L63.6151967,93.9580952 L71.5188331,80.5904762 L86.6151967,77.1542857 L85.1933786,61.6914286 L95.3970149,50 Z M41.4097422,69.7790476 L25.5188331,53.8133333 L31.707924,47.6114286 L41.4097422,57.3752381 L65.8733786,32.7771429 L72.0624695,38.9790476 L41.4097422,69.7790476 Z"/>
            </g>
            <g opacity=".1" transform="translate(379.08 14)">
              <polygon points="-.159 0 99.841 0 99.841 100 -.159 100"/>
              <path fill="#000" fillRule="nonzero"
                    d="M80.466194,42.915 C77.6328607,28.68375 65.0078607,18 49.841194,18 C37.7995274,18 27.341194,24.765 22.1328607,34.665 C9.59119403,35.985 -0.15880597,46.50375 -0.15880597,59.25 C-0.15880597,72.90375 11.0495274,84 24.841194,84 L79.0078607,84 C90.5078607,84 99.841194,74.76 99.841194,63.375 C99.841194,52.485 91.2995274,43.6575 80.466194,42.915 Z M58.1745274,55.125 L58.1745274,71.625 L41.5078607,71.625 L41.5078607,55.125 L29.0078607,55.125 L49.841194,34.5 L70.6745274,55.125 L58.1745274,55.125 Z"/>
            </g>
            <g opacity=".1" transform="translate(666.578 14)">
              <polygon points="-1.156 0 98.844 0 98.844 100 -1.156 100"/>
              <path fill="#000" fillRule="nonzero"
                    d="M48.8021791,8 C25.6181791,8 6.8441791,26.816 6.8441791,50 C6.8441791,73.184 25.6181791,92 48.8021791,92 C72.0281791,92 90.8441791,73.184 90.8441791,50 C90.8441791,26.816 72.0281791,8 48.8021791,8 Z M77.9081791,33.2 L65.5181791,33.2 C64.1741791,27.95 62.2421791,22.91 59.7221791,18.248 C67.4501791,20.894 73.8761791,26.27 77.9081791,33.2 Z M48.8441791,16.568 C52.3301791,21.608 55.0601791,27.194 56.8661791,33.2 L40.8221791,33.2 C42.6281791,27.194 45.3581791,21.608 48.8441791,16.568 Z M16.3361791,58.4 C15.6641791,55.712 15.2441791,52.898 15.2441791,50 C15.2441791,47.102 15.6641791,44.288 16.3361791,41.6 L30.5321791,41.6 C30.1961791,44.372 29.9441791,47.144 29.9441791,50 C29.9441791,52.856 30.1961791,55.628 30.5321791,58.4 L16.3361791,58.4 Z M19.7801791,66.8 L32.1701791,66.8 C33.5141791,72.05 35.4461791,77.09 37.9661791,81.752 C30.2381791,79.106 23.8121791,73.772 19.7801791,66.8 L19.7801791,66.8 Z M32.1701791,33.2 L19.7801791,33.2 C23.8121791,26.228 30.2381791,20.894 37.9661791,18.248 C35.4461791,22.91 33.5141791,27.95 32.1701791,33.2 L32.1701791,33.2 Z M48.8441791,83.432 C45.3581791,78.392 42.6281791,72.806 40.8221791,66.8 L56.8661791,66.8 C55.0601791,72.806 52.3301791,78.392 48.8441791,83.432 Z M58.6721791,58.4 L39.0161791,58.4 C38.6381791,55.628 38.3441791,52.856 38.3441791,50 C38.3441791,47.144 38.6381791,44.33 39.0161791,41.6 L58.6721791,41.6 C59.0501791,44.33 59.3441791,47.144 59.3441791,50 C59.3441791,52.856 59.0501791,55.628 58.6721791,58.4 Z M59.7221791,81.752 C62.2421791,77.09 64.1741791,72.05 65.5181791,66.8 L77.9081791,66.8 C73.8761791,73.73 67.4501791,79.106 59.7221791,81.752 L59.7221791,81.752 Z M67.1561791,58.4 C67.4921791,55.628 67.7441791,52.856 67.7441791,50 C67.7441791,47.144 67.4921791,44.372 67.1561791,41.6 L81.3521791,41.6 C82.0241791,44.288 82.4441791,47.102 82.4441791,50 C82.4441791,52.898 82.0241791,55.712 81.3521791,58.4 L67.1561791,58.4 Z"/>
            </g>
            <text fill="#000" fontFamily="sans-serif" fontSize="20" fontWeight="400">
              <tspan x="18" y="43">1. Share your SSL</tspan>
              <tspan x="18" y="72">certificate with</tspan>
              <tspan x="18" y="101">Zycada</tspan>
            </text>
            <text fill="#000" fontFamily="sans-serif" fontSize="20" fontWeight="400">
              <tspan x="342" y="43">2. Zycada installs</tspan>
              <tspan x="342" y="72">certificate on</tspan>
              <tspan x="342" y="101">Zycada cloud</tspan>
            </text>
            <text fill="#000" fontFamily="sans-serif" fontSize="20" fontWeight="400">
              <tspan x="623" y="43">3. Point your DNS</tspan>
              <tspan x="623" y="72">records to</tspan>
              <tspan x="623" y="101">Zycada cloud</tspan>
            </text>
          </g>
        </svg>

        <svg className="flex w-3/5 sm:hidden mx-auto" xmlns="http://www.w3.org/2000/svg" width="871"
             viewBox="0 0 221 628">
          <g fill="none" fillRule="evenodd" transform="translate(2.5 3)">
            <path stroke="#FF6329" strokeWidth="5"
                  d="M177.828252,405.679611 L215.459831,512.679611 L177.828252,619.679611 L-4.54016899,619.679611 L-4.53938877,616.863169 L32.459831,512.679611 L-4.53938877,408.496169 L-4.54016899,405.679611 L177.828252,405.679611 Z"
                  transform="rotate(90 107.57 513.61)"/>
            <path stroke="#FF6329" strokeWidth="5"
                  d="M177.980363,204.0275 L215.611942,311.0275 L177.980363,418.0275 L-4.38805795,418.0275 L33.6119421,311.0275 L-4.38805795,204.0275 L177.980363,204.0275 Z"
                  transform="rotate(90 107.57 311.958)"/>
            <polygon stroke="#FF6329" strokeWidth="5"
                     points="-1.821 2.682 181.088 2.682 218.682 110.252 181.088 217.821 -1.821 217.821"
                     transform="rotate(90 108.43 110.252)"/>
            <g opacity=".1" transform="translate(57.801 48)">
              <rect width="100" height="100" x="1.397"/>
              <path fill="#000" fillRule="nonzero"
                    d="M95.3970149,50 L85.1933786,38.3085714 L86.6151967,22.8457143 L71.5188331,19.4095238 L63.6151967,6 L49.3970149,12.1180952 L35.1788331,6 L27.2751967,19.367619 L12.1788331,22.7619048 L13.6006513,38.2666667 L3.39701493,50 L13.6006513,61.6914286 L12.1788331,77.1961905 L27.2751967,80.632381 L35.1788331,94 L49.3970149,87.84 L63.6151967,93.9580952 L71.5188331,80.5904762 L86.6151967,77.1542857 L85.1933786,61.6914286 L95.3970149,50 Z M41.4097422,69.7790476 L25.5188331,53.8133333 L31.707924,47.6114286 L41.4097422,57.3752381 L65.8733786,32.7771429 L72.0624695,38.9790476 L41.4097422,69.7790476 Z"/>
            </g>
            <g opacity=".1" transform="translate(53.58 262)">
              <polygon points="-.159 0 99.841 0 99.841 100 -.159 100"/>
              <path fill="#000" fillRule="nonzero"
                    d="M80.466194,42.915 C77.6328607,28.68375 65.0078607,18 49.841194,18 C37.7995274,18 27.341194,24.765 22.1328607,34.665 C9.59119403,35.985 -0.15880597,46.50375 -0.15880597,59.25 C-0.15880597,72.90375 11.0495274,84 24.841194,84 L79.0078607,84 C90.5078607,84 99.841194,74.76 99.841194,63.375 C99.841194,52.485 91.2995274,43.6575 80.466194,42.915 Z M58.1745274,55.125 L58.1745274,71.625 L41.5078607,71.625 L41.5078607,55.125 L29.0078607,55.125 L49.841194,34.5 L70.6745274,55.125 L58.1745274,55.125 Z"/>
            </g>
            <g opacity=".1" transform="translate(60.078 465)">
              <polygon points="-1.156 0 98.844 0 98.844 100 -1.156 100"/>
              <path fill="#000" fillRule="nonzero"
                    d="M48.8021791,8 C25.6181791,8 6.8441791,26.816 6.8441791,50 C6.8441791,73.184 25.6181791,92 48.8021791,92 C72.0281791,92 90.8441791,73.184 90.8441791,50 C90.8441791,26.816 72.0281791,8 48.8021791,8 Z M77.9081791,33.2 L65.5181791,33.2 C64.1741791,27.95 62.2421791,22.91 59.7221791,18.248 C67.4501791,20.894 73.8761791,26.27 77.9081791,33.2 Z M48.8441791,16.568 C52.3301791,21.608 55.0601791,27.194 56.8661791,33.2 L40.8221791,33.2 C42.6281791,27.194 45.3581791,21.608 48.8441791,16.568 Z M16.3361791,58.4 C15.6641791,55.712 15.2441791,52.898 15.2441791,50 C15.2441791,47.102 15.6641791,44.288 16.3361791,41.6 L30.5321791,41.6 C30.1961791,44.372 29.9441791,47.144 29.9441791,50 C29.9441791,52.856 30.1961791,55.628 30.5321791,58.4 L16.3361791,58.4 Z M19.7801791,66.8 L32.1701791,66.8 C33.5141791,72.05 35.4461791,77.09 37.9661791,81.752 C30.2381791,79.106 23.8121791,73.772 19.7801791,66.8 L19.7801791,66.8 Z M32.1701791,33.2 L19.7801791,33.2 C23.8121791,26.228 30.2381791,20.894 37.9661791,18.248 C35.4461791,22.91 33.5141791,27.95 32.1701791,33.2 L32.1701791,33.2 Z M48.8441791,83.432 C45.3581791,78.392 42.6281791,72.806 40.8221791,66.8 L56.8661791,66.8 C55.0601791,72.806 52.3301791,78.392 48.8441791,83.432 Z M58.6721791,58.4 L39.0161791,58.4 C38.6381791,55.628 38.3441791,52.856 38.3441791,50 C38.3441791,47.144 38.6381791,44.33 39.0161791,41.6 L58.6721791,41.6 C59.0501791,44.33 59.3441791,47.144 59.3441791,50 C59.3441791,52.856 59.0501791,55.628 58.6721791,58.4 Z M59.7221791,81.752 C62.2421791,77.09 64.1741791,72.05 65.5181791,66.8 L77.9081791,66.8 C73.8761791,73.73 67.4501791,79.106 59.7221791,81.752 L59.7221791,81.752 Z M67.1561791,58.4 C67.4921791,55.628 67.7441791,52.856 67.7441791,50 C67.7441791,47.144 67.4921791,44.372 67.1561791,41.6 L81.3521791,41.6 C82.0241791,44.288 82.4441791,47.102 82.4441791,50 C82.4441791,52.898 82.0241791,55.712 81.3521791,58.4 L67.1561791,58.4 Z"/>
            </g>
            <text fill="#000" fontFamily="sans-serif" fontSize="20" fontWeight="400">
              <tspan x="16.5" y="72">1. Share your SSL</tspan>
              <tspan x="16.5" y="101">certificate with</tspan>
              <tspan x="16.5" y="130">Zycada</tspan>
            </text>
            <text fill="#000" fontFamily="sans-serif" fontSize="20" fontWeight="400">
              <tspan x="16.5" y="291">2. Zycada installs</tspan>
              <tspan x="16.5" y="320">certificate on</tspan>
              <tspan x="16.5" y="349">Zycada cloud</tspan>
            </text>
            <text fill="#000" fontFamily="sans-serif" fontSize="20" fontWeight="400">
              <tspan x="16.5" y="494">3. Point your DNS</tspan>
              <tspan x="16.5" y="523">records to</tspan>
              <tspan x="16.5" y="552">Zycada cloud</tspan>
            </text>
          </g>
        </svg>


      </div>
    </div>
  </div>
)

IntegrateZycada.propTypes = {
  siteTitle: PropTypes.string,
}

IntegrateZycada.defaultProps = {
  siteTitle: ``,
}

export default IntegrateZycada
