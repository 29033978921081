/** @jsx jsx */
import * as React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import tw, { styled } from "twin.macro"
import { jsx, css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReact } from "@fortawesome/free-brands-svg-icons"
import { faArrowRight, faBrain, faBolt, faAsterisk } from "@fortawesome/free-solid-svg-icons"
import StandardPromoBlock from "../components/standardPromoBlock"
import IntegrateZycada from "../components/integrateZycada"
import { graphql } from 'gatsby'
import stringify from "postcss/lib/stringify"
import Modal from "react-modal"
import ReactJWPlayer from "react-jw-player"
import { Formik } from "formik"
import * as Yup from "yup"

const fieldStyles = " w-60 rounded-none border border-black focus:rounded-none focus:outline-none focus:ring focus:border-blue-300 mb-4 p-2"

const containsFreeEmail = (string) => /(hotmail.com)|(gmail.com)|(outlook.com)|(yahoo.com)|(aol.com)|(protonmail.com)/.test(string)


const customStyles = {
  overlay: {
      backgroundColor: "rgba(0, 0, 0, .9)",
  },
  content: {
    background: "none",
    // backgroundColor: '#000',
    padding: "0",
    marginTop: "20vh",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "25rem",
    // height: '80vh',
    border: "0",
    position: "unset",
    height: "auto",
    display: "flex",
    justifyContent: "center"
    // top                   : '50%',
    // left                  : '50%',
    // right                 : 'auto',
    // bottom                : 'auto',
    // marginRight           : '-50%',
    // transform             : 'translate(-50%, -50%)'
  }
}

const WhitePaperForm = () => {
  return (
    <div>
    <Formik
      initialValues={ { email: "" } }

      onSubmit={async (values,  onsubmitProps, setStatus) => {
        await new Promise(resolve => setTimeout(resolve, 500))
        // alert(JSON.stringify(values, null, 2));
        const completedFormValues = values
        console.log(JSON.stringify(values, null, 2))
        const url = 'https://api.hsforms.com/submissions/v3/integration/submit/19556060/ab2381da-3ece-4785-8f17-e54eceefee35'
        const xhr = new XMLHttpRequest();

        const dataToSendToHubspot = {
          "fields": [
            {
              "name": "email",
              "value": values.email
            },
          ],
          "context": {
            "pageUri": "www.zycada.com/resources",
            "pageName": "Resources"
          },
        }

        const final_data = (JSON.stringify(dataToSendToHubspot, null, 2))
        console.log(final_data)
        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');
        // xhr.onreadystatechange = function () {
        //   if (xhr.readyState === 4 && xhr.status === 200) {
        //     alert(xhr.responseText); // Returns a 200 response if the submission is successful.
        //   } else if (xhr.readyState === 4 && xhr.status === 403) {
        //     alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        //   } else if (xhr.readyState === 4 && xhr.status === 404) {
        //     alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
        //   }
        // }
// Sends the request
        xhr.send(final_data)

        navigate("/thankyou",
          {
            state: { completedFormValues }
          })
      }}
      enableReinitialize

      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Please enter a valid email.")
          .required("Email is required.")
          .test(
            "Please use your work email.",
            "Please use your work email.",
            (value) => !containsFreeEmail(value)),
      })}
    >{props => {
      const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset
      } = props
      return (

        <form
          onSubmit={handleSubmit}
          className={""}
        >
          <label htmlFor="email" style={{ display: "block" }}>
          </label>
          <div className={"flex flex-col"}>
            <h1 className={"text-white text-center"}>Please provide your work email address.</h1>
            <h2 className={"text-white text-center"}>We'll send you a download link.</h2>
            <div className={"flex justify-center"}>


              <input
                name={"email"}
                id="email"
                placeholder="Work email..."
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                className={fieldStyles}
              />
              {/*&nbsp;<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>*/}
            </div>

            <div className={"flex justify-center"}>
                <button
                    className={
                        "relative text-white  p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 "
                    }
                    type="submit" disabled={isSubmitting}
                >

                Submit &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;</button>
            </div>
          </div>
          <div className={"flex flex-col justify-center"}>
            {errors.email && touched.email && (
              <div className="text-white text-center"><br/><p className={"text-orange"}>{errors.email}</p></div>
            )}
            {errors.website && touched.website && (
              <div className="text-center text-center"><br/><p className={"text-orange"}>{errors.website}</p></div>
            )}
            {errors.phone && touched.phone && (
              <div className="text-center text-center"><br/><p className={"text-orange"}>{errors.phone}</p></div>
            )}
            {errors.firstName && touched.firstName && (
              <div className="text-center text-center"><br/><p className={"text-orange"}>{errors.firstName}</p></div>
            )}
            {errors.lastName && touched.lastName && (
              <div className="text-center text-center"><br/><p className={"text-orange"}>{errors.lastName}</p></div>
            )}
          </div>
        </form>
      )
    }}
    </Formik>
    </div>
  )
}

Modal.setAppElement("body")

function ResourcesModal() {
  // let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#FFF"
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div>

        <button
            className={
                "relative text-white  p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 "
            }
            onClick={openModal}>
            Download Whitepaper &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;
        </button>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Zycada video modal"
        overlayClassName={""}
      >
        <WhitePaperForm></WhitePaperForm>


      </Modal>
    </div>
  )
}

export default function Resources({ data }) {
  const { posts } = data.blog
  const { studies } = data.caseStudy
  const { releases } = data.press

  return (
    <Layout>
      <SEO title="Zycada - Resources. Download whitepapers, case studies and more."/>

      <section className={"pt-32"}>

        <div className="flex justify-center p-4 mx-auto sm:w-4/5">
          <div className={"flex flex-col"}>

            <div className="flex flex-col mb-8">
              <h1 className={"text-center text-zycadaDarkPurple"}>Download the Zycada whitepaper</h1>
              <div className={"flex flex-col sm:flex-row justify-center "}>
                {/*<div className={"w-full"}>*/}
                {/*  <StaticImage*/}
                {/*    src="../images/zycadaBotLookingDownRight.png"*/}
                {/*    className={"mx-12"}*/}
                {/*    quality={95}*/}
                {/*    height={150}*/}
                {/*    objectFit={"contain"}*/}
                {/*    trim={"true"}*/}
                {/*    formats={["AUTO", "WEBP", "AVIF", "PNG"]}*/}
                {/*    alt="Zycada whitepaper download"*/}
                {/*    styles={{}}*/}
                {/*    loading={"eager"}*/}
                {/*    placeholder={"none"}/>*/}
                {/*</div>*/}
                <div className={"text-center w-full"}>
                  <h2 className={"text-zycadaDarkPurple"}>App-aware eCommerce Acceleration with Zycada</h2>
                  <ResourcesModal></ResourcesModal>
                </div>
              </div>
            </div>


          </div>

        </div>
      </section>

      <section>
        <div className={"flex justify-center"}>
          <div className="flex flex-wrap justify-center p-4 mb-8 md:w-4/5">
            <div className={"w-full text-center"}>
              <h2 className={"text-zycadaDarkPurple"}>Zycada case studies</h2>
            </div>
            <div className={"grid grid-cols-2 sm:grid-cols-3 gap-4 justify-center p-4 "}>
              {studies.map(post => {
                const image = getImage(post.frontmatter.featuredImage)
                return (
                  <article key={post.id}>
                    <Link to={post.fields.slug}>
                      {/*<small>{post.frontmatter.author}, {post.frontmatter.date}</small>*/}
                      {/*<p>{post.excerpt}</p>*/}
                      <GatsbyImage
                        className={"sm:mx-12"}
                        placeholder={"none"}
                        alt={post.frontmatter.title}
                        image={image} />
                      <h4 className={"text-center"}>{post.frontmatter.title}</h4>


                    </Link>
                  </article>
                )
              })}

            </div>
          </div>
        </div>
      </section>


      <section>
        <div className={"flex justify-center"}>
          <div className="flex flex-wrap justify-center p-4 mb-8 md:w-4/5">
            <div className={"w-full text-center"}>
                <h2 className={"text-zycadaDarkPurple"}>Blog posts</h2>
            </div>
            <div className={"grid grid-cols-2 sm:grid-cols-3 gap-4 justify-center p-4 "}>
              {posts.map(post => {
                const image = getImage(post.frontmatter.featuredImage)
                return (

                  <article key={post.id}>
                    <Link className={"flex flex-col items-center"} to={post.fields.slug}>
                      {/*<small>{post.frontmatter.author}, {post.frontmatter.date}</small>*/}
                      {/*<p>{post.excerpt}</p>*/}
                      <GatsbyImage
                        className={"sm:mx-12"}
                        placeholder={"none"}
                        alt={post.frontmatter.title}
                        image={image} />
                      <h4 className={"text-center w-4/5"}>{post.frontmatter.title}</h4>


                    </Link>
                  </article>
                )
              })}


            </div>
          </div>
        </div>
      </section>

      <section>

        <div className={"flex justify-center"}>
          <div className="flex flex-wrap justify-center p-4 mb-8 md:w-4/5">
            <div className={"w-full text-center"}>
                <h2 className={"text-zycadaDarkPurple"}>Press</h2>
            </div>
            <div className={"grid grid-cols-2 sm:grid-cols-3 gap-4 justify-center p-4 "}>
              {releases.map(post => {
                const image = getImage(post.frontmatter.featuredImage)
                return (
                  <article key={post.id}>
                    <Link className={"flex flex-col items-center"}  to={post.fields.slug}>
                      {/*<small>{post.frontmatter.author}, {post.frontmatter.date}</small>*/}
                      {/*<p>{post.excerpt}</p>*/}
                      <GatsbyImage
                        className={"sm:mx-12"}
                        placeholder={"none"}
                        alt={post.frontmatter.title}
                        image={image} />
                      <h4 className={"text-center w-4/5"}>{post.frontmatter.title}</h4>


                    </Link>
                  </article>
                )
              })}


            </div>
          </div>
        </div>

      </section>


    </Layout>
  )
}

export const pageQuery = graphql`
    query MyQuery {
        blog: allMdx (
            sort: {fields: frontmatter___path, order: ASC}
            filter: {frontmatter: {contentType: {eq: "blogPost"}}}
        )
        {
            posts: nodes {
                frontmatter {
                    date(fromNow: true)
                    title
                    contentType
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                excerpt
                id
                fields {
                    slug
                }
            }
        }
        caseStudy: allMdx (
            sort: {fields: frontmatter___path, order: ASC}
            filter: {frontmatter: {contentType: {eq: "caseStudy"}}}
        )
        {
            studies: nodes {
                frontmatter {
                    date(fromNow: true)
                    title
                    contentType
                    featuredImage {
                        childImageSharp {
                          gatsbyImageData
                        }
                    }
                }
                excerpt
                id
                fields {
                    slug
                }
            }
        }
        press: allMdx (
            sort: {fields: frontmatter___path, order: ASC}
            filter: {frontmatter: {contentType: {eq: "press"}}}
        )
        {
            releases: nodes {
                frontmatter {
                    date(fromNow: true)
                    title
                    contentType
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                excerpt
                id
                fields {
                    slug
                }
            }
        }

    }
`


